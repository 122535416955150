.mainCont {
  display: grid;
  grid-template-columns: 50px 2fr 1fr 3fr 50px;
  margin-top: 30px;
}

.leftCont {
  grid-column: 2;
  overflow: hidden;
}

.bioCont {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
}

.bioBox {
  border: 1px solid grey;
  grid-column: 2;
  margin-top: 10px;
  height: 200px;
}

.rightCard {
  grid-column: 4;
}

.hr {
  margin: 10px;
}

.username {
  margin-top: 10px;
}

.editButton {
  font-size: x-small;
  width: 50px;
  height: 20px;
  border-radius: 10px;
  margin-bottom: 3px;
}

.editButton:hover {
  background: rgb(2, 160, 160);
}

.profileCard {
  margin-bottom: 10px;
  min-height: 300px;
  overflow: hidden;
  height: 400px;
}

.comCard {
  min-height: 50px;
}

.textArea {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 450px) {
  .mainCont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    justify-items: center;
    align-items: center;
    text-align: center;
  }

  .profileCard {
    margin-right: auto;
    margin-left: auto;
  }

  .comCard {
    margin-right: auto;
    margin-left: auto;
  }

  .postHistoryTitle {
    margin-right: auto;
    margin-left: auto;
    margin-top: 20px;
    width: 100%;
  }
}
