.regCont {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.regCard {
  grid-column: 2;
}

.passwordCont {
  position: relative;
}

.eye{
  position: absolute;
  top: 57%;
  right: 1%;
  cursor: pointer;
  color: black;
}

@media only screen and (max-width: 450px) {
  .regCont {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}