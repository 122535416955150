.postCard {
  margin-top: 10px;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: 50px 1fr;
  word-break: normal;
}

.cardTop {
  text-align: left;
  position: relative;
}

.username {
  color: rgb(175, 159, 215);
  margin-left: 5px;
}

.community {
  position: absolute;
  right: 45%;
}

.date {
  color: grey;
  position: absolute;
  right: 5px;
}

.points {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.button {
  border-radius: 5px;
  background: rgba(128, 128, 128, 0.346);
}

.cardLeft {
  border-right: 1px solid grey;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.like {
  border-bottom: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.like:hover {
  background-color: darkcyan;
  border-radius: 5px 0px 0px 0px;
}

.dislike {
  border-top: 1px solid grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dislike:hover {
  background-color: darkcyan;
  border-radius: 0px 0px 0px 5px;
}

.deleteButton {
  border-radius: 10px;
  margin-bottom: 3px;
}

.deleteButton:hover {
  background: rgb(2, 160, 160);
}

@media only screen and (max-width: 450px) {
  .postCard {
    width: 100%;
  }
}

