.panel {
  margin-bottom: 10px;
  padding-top: 10px;
}

.icon {
  margin-bottom: 5px;
}

.subButton {
  border: 1px solid grey;
  border-radius: 15px;
  margin-top: 15px;
  cursor: pointer;
}

.subButton:hover {
  background-color: rgb(96, 96, 96);
}

@media only screen and (max-width: 450px) {
  .panel {
    margin-left: auto;
    margin-right: auto;
  }
}