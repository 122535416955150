.feedCont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.leftPanel {
  position: fixed;
  width: 30%;
  margin-top: 10px;
  margin-left: 5px;
}

.postCol {
  grid-column: 2;
}

.rightPanel {
  margin-top: 10px;
  margin-right: 10px;
  grid-column: 5;
  border: 1px solid black;
  position: fixed;
  width: 30%;
  right: 0;
  min-height: 50vh;
  overflow: hidden;
}

.sortBtnCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  height: 40px;
  margin-top: 10px;
}

.sortBtn {
  border: 1px solid slategray;
  border-radius: 15px;
  padding: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.sortBtn:hover {
  background-color: slategray;
}

.communityBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.inCommunity {
  border: 1px solid black;
  border-radius: 15px;
  box-sizing: border-box;
  width: 100px;
  text-decoration: none;
  color: white;
}

.inCommunity:hover {
  background-color: darkgray;
  cursor: pointer;
}

.members {
  color: darkgrey;
}

@media only screen and (max-width: 450px) {
  .feedCont {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
  }

  .postCol {
    grid-column: 2;
    width: 100%;
  }
}
