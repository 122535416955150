.contentInput {
  width: 100%;
  border-radius: 10px;
  background: rgb(63, 105, 105);
  color: white;
}

.contentInput:focus {
  outline: none !important;
}

.cardTitle {
  position: relative;
  text-align: left;
}

.username {
  color: rgb(175, 159, 215);
  margin-left: 5px;
}

.date {
  position: absolute;
  color: grey;
  right: 5px;
}

.belowInput {
  margin-top: 2px;
}

.submitButton {
  background-color: rgb(216, 197, 197);
  border-color: whitesmoke;
}

.newPost {
  width: 100%;
}