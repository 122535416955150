body {
  text-align: center;
  background-color: rgb(8, 21, 21);
  color: whitesmoke;
}

input {
  text-align: center;
}

textarea {
  text-align: center;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar {
  position: fixed;
  width: 100%;
}

.wrapper {
  min-height: 100%;
  margin-bottom: 50px;
}

.push {
  height: 60px;
}

.card {
  background-color: darkslategray;
}

.light-mode {
  background-color: lightcyan;
  color: black;
}

.cardLightMode {
  background-color: whitesmoke;
}

.navLightMode {
  background-color: white;
}

#textAreaLightMode {
  background: #ddf2f9;
  color: black;
}

#linkLightMode {
  color: black;
}

@media only screen and (max-width: 450px) {
  .allTodosCont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 85%;
  }
}
